<template>
  <!--   我的题目 -->
  <div class="">
    <div v-if="!$attrs.isCollect">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input
            placeholder="请输入题号"
            v-model="answer_log_params.quest_id"
            clearable
            size="small"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入题目名称"
            v-model="answer_log_params.title"
            clearable
            size="small"
          >
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="answer_log_params.type"
            placeholder="请选择题目类型"
            clearable
            size="small"
          >
            <el-option
              v-for="item in options.type3.list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            v-model="answer_log_params.result"
            placeholder="请选择题目结果"
            clearable
            size="small"
          >
            <el-option
              v-for="item in options.result.list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" size="small" @click="search_list()"
            >搜 索</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- <el-tabs
      v-model="activeName"
      @tab-click="changeTab"
    >
      <el-tab-pane
        :label="tab.name"
        :name="tab.value"
        v-for="(tab, index) in tabList"
        :key="index"
      ></el-tab-pane>
    </el-tabs> -->
    <div class="box">
      <com-table
        :columns="[
          { align: 'center', label: '题号', prop: 'id', width: 65 },
          {
            align: 'center',
            label: '题目标题',
            prop: 'title',
            minWidth: 200,
            template: ({ row }) => {
              return row.title;
            },
          },
          { align: 'center', label: '题目类型', prop: 'type_str', width: 145 },
          {
            align: 'center',
            label: '来源',
            prop: 'from_title',
            minWidth: 100,
            slot: 'color',
            prop_color: 'from_color',
          },
          {
            align: 'center',
            label: '结果',
            prop: 'id',
            width: 200,
            hidden: $attrs.isCollect,
            slot: 'result',
          },
          {
            align: 'center',
            label: '难度',
            prop: 'diff_title',
            minWidth: 100,
            slot: 'color',
            prop_color: 'diff_color',
          },
          {
            align: 'center',
            label: '答题详情',
            width: 90,
            slot: 'action1',
            hidden: $attrs.isCollect,
          },
          {
            align: 'center',
            label: '操作',
            slot: 'action2',
            hidden: userInfo.type !== 2,
          },
        ]"
        :data="tableData"
        v-loading="loading"
        ref="singleTable"
        highlight-current-row
        @current-change="handleCurrentChange"
        @row-click="$handleRoute({ question_id: $event.id }, 'QuestionInfo')"
        :page-options="false"
      >
        <template slot="result" slot-scope="scope">
          <div
            class="button"
            :style="{
              padding: '0 10px',
              '--color-default': (scope.row.remark || {}).color || '#000',
            }"
          >
            {{ (scope.row.remark || {}).label || "" }}
          </div>
          <div
            class="button"
            :style="{
              padding: '0 10px',
              '--color-default': (scope.row.remark || {}).color || '#000',
            }"
          >
            {{ (scope.row.remark || {}).value || 0 }}
          </div>
        </template>
        <template slot="action1" slot-scope="scope">
          <div
            class="button-primary"
            :style="{ width: '100%' }"
            v-href="{
              path: '/topic/topicRecord',
              query: { id: scope.row.id, log_id: scope.row.log_id },
            }"
            @click.stop="
              $handleRoute(
                { id: scope.row.id, log_id: scope.row.log_id },
                '/topic/topicRecord'
              )
            "
            v-if="scope.row.log_id"
          >
            查看详情
          </div>
        </template>
        <template slot="action2" slot-scope="scope">
          <template v-if="!$attrs.isCollect">
            <i
              v-if="userInfo.id == scope.row.user_id"
              @click.stop="deleteRow(scope.row.id)"
              class="el-icon-delete-solid"
              style="color: #414141; font-size: 20px"
            ></i>
          </template>
          <template v-else>
            <i
              class="el-icon-star-on"
              style="color: #f56c6c; font-size: 22px"
              @click.stop="meCollect(scope.row.id, scope.$index)"
            ></i>
          </template>
        </template>
      </com-table>
    </div>
    <!-- 分页 -->
    <div class="page" :style="$attrs.pageStyle || {}" v-if="total > 0">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        :total="total"
        @current-change="subjectList"
        @size-change="onSizeChange"
        :page-size="pageSize"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { question, delQuestion, meCollect, my_question } from "@/api/me.js";
import { options } from "@/api/topic.js";
export default {
  props: {
    student_user_id: {
      default: () => 0,
    },
  },
  data() {
    //这里存放数据
    return {
      loading: false,
      //答题详情请求筛选
      answer_log_params: {
        type: "",
        result: "",
        title: "",
        quest_id: "",
      },
      options: {
        case_judge_reault: { list: [] },
        language: { list: [] },
        type3: { list: [] },
        result: { list: [] },
      },
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      currentRow: {},
      where: {},
      activeName: "all",
      tabList: [
        { name: "全部", value: "all", params: {match_type:0} },
        { name: "我创建的", value: "create", params: { match_type: 1 } },
        { name: "我提交的", value: "submit", params: { match_type: 2 } },
      ],
      curTabList: [],
    };
  },
  //生命周期 - 创建完成（可以访问当前this实例）s
  mounted() {
    //我的题目
    options({}).then((res) => {
      this.options = res.data;
    });
    this.subjectList();
  },
  //方法集合
  methods: {
    //搜索
    search_list() {
      this.page = 1;
      this.subjectList();
    },
    //切换tab
    changeTab(e,v){
      console.log(e.index,"indexindex");
      // console.log(v,"vvvvvvv");
      this.matchType=Number(e.index)
      this.subjectList();

    },
    //  我的题目
    subjectList(page) {
      this.page = page || 1;
      this.loading = true;
      // my_question({
      //     page: this.page,
      //     page_size: this.pageSize,
      //     match_type:this.match_type,
      //     ...this.answer_log_params,
      //   })
      //     .then(({ data }) => {
      //       console.log(data,"datadata");
      //       this.tableData = data.data;
      //       this.total = data.total;
      //     })
      //     .finally(() => {
      //       this.loading = false;
      //     });

      console.log("subjectList --- ", this.$parent);
      if (this.$attrs.isCollect) {
        question({
          page: this.page,
          page_size: this.pageSize,
          ...this.answer_log_params,
        })
          .then(({ data }) => {
            this.tableData = data.data;
            this.total = data.total;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        my_question({
          page: this.page,
          page_size: this.pageSize,
          match_type:this.matchType?this.matchType:0,
          ...this.answer_log_params,
        })
          .then(({ data }) => {
            this.tableData = data.data;
            this.total = data.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onSizeChange(pagesize){
      this.pageSize=pagesize
      this.subjectList();
    },
    // 删除
    deleteRow(id) {
      delQuestion({ id: id })
        .then(({ data }) => {
          this.getList();
          this.$message.success("删除成功");
        })
        .catch((err) => {});
    },

    // 收藏
    meCollect(id, index) {
      console.log(id, index);
      meCollect({ id: id, type: 3 })
        .then((res) => {
          // console.log(data,'删除成功');
          this.$message.success(res.msg);
          this.subjectList();
          // this.tableData[index].is_collect=true
        })
        .catch((err) => {});
    },
    rowClick(e) {
      console.log(e.id);
      this.$handleRoute({ id: e.id }, "QuestionInfo");
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
  },
};
</script>
<style  lang='less' scoped>
.box {
  margin-top: 1rem;
  border: 1px solid #979797;
  .img {
    width: 20px;
    height: 20px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #01957e;
  }
}
</style>