var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(!_vm.$attrs.isCollect)?_c('div',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":4}},[_c('el-input',{attrs:{"placeholder":"请输入题号","clearable":"","size":"small"},model:{value:(_vm.answer_log_params.quest_id),callback:function ($$v) {_vm.$set(_vm.answer_log_params, "quest_id", $$v)},expression:"answer_log_params.quest_id"}})],1),_c('el-col',{attrs:{"span":4}},[_c('el-input',{attrs:{"placeholder":"请输入题目名称","clearable":"","size":"small"},model:{value:(_vm.answer_log_params.title),callback:function ($$v) {_vm.$set(_vm.answer_log_params, "title", $$v)},expression:"answer_log_params.title"}})],1),_c('el-col',{attrs:{"span":5}},[_c('el-select',{attrs:{"placeholder":"请选择题目类型","clearable":"","size":"small"},model:{value:(_vm.answer_log_params.type),callback:function ($$v) {_vm.$set(_vm.answer_log_params, "type", $$v)},expression:"answer_log_params.type"}},_vm._l((_vm.options.type3.list),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-col',{attrs:{"span":5}},[_c('el-select',{attrs:{"placeholder":"请选择题目结果","clearable":"","size":"small"},model:{value:(_vm.answer_log_params.result),callback:function ($$v) {_vm.$set(_vm.answer_log_params, "result", $$v)},expression:"answer_log_params.result"}},_vm._l((_vm.options.result.list),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-col',{attrs:{"span":5}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.search_list()}}},[_vm._v("搜 索")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"box"},[_c('com-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"singleTable",attrs:{"columns":[
        { align: 'center', label: '题号', prop: 'id', width: 65 },
        {
          align: 'center',
          label: '题目标题',
          prop: 'title',
          minWidth: 200,
          template: ({ row }) => {
            return row.title;
          },
        },
        { align: 'center', label: '题目类型', prop: 'type_str', width: 145 },
        {
          align: 'center',
          label: '来源',
          prop: 'from_title',
          minWidth: 100,
          slot: 'color',
          prop_color: 'from_color',
        },
        {
          align: 'center',
          label: '结果',
          prop: 'id',
          width: 200,
          hidden: _vm.$attrs.isCollect,
          slot: 'result',
        },
        {
          align: 'center',
          label: '难度',
          prop: 'diff_title',
          minWidth: 100,
          slot: 'color',
          prop_color: 'diff_color',
        },
        {
          align: 'center',
          label: '答题详情',
          width: 90,
          slot: 'action1',
          hidden: _vm.$attrs.isCollect,
        },
        {
          align: 'center',
          label: '操作',
          slot: 'action2',
          hidden: _vm.userInfo.type !== 2,
        },
      ],"data":_vm.tableData,"highlight-current-row":"","page-options":false},on:{"current-change":_vm.handleCurrentChange,"row-click":function($event){return _vm.$handleRoute({ question_id: $event.id }, 'QuestionInfo')}},scopedSlots:_vm._u([{key:"result",fn:function(scope){return [_c('div',{staticClass:"button",style:({
            padding: '0 10px',
            '--color-default': (scope.row.remark || {}).color || '#000',
          })},[_vm._v(" "+_vm._s((scope.row.remark || {}).label || "")+" ")]),_c('div',{staticClass:"button",style:({
            padding: '0 10px',
            '--color-default': (scope.row.remark || {}).color || '#000',
          })},[_vm._v(" "+_vm._s((scope.row.remark || {}).value || 0)+" ")])]}},{key:"action1",fn:function(scope){return [(scope.row.log_id)?_c('div',{directives:[{name:"href",rawName:"v-href",value:({
            path: '/topic/topicRecord',
            query: { id: scope.row.id, log_id: scope.row.log_id },
          }),expression:"{\n            path: '/topic/topicRecord',\n            query: { id: scope.row.id, log_id: scope.row.log_id },\n          }"}],staticClass:"button-primary",style:({ width: '100%' }),on:{"click":function($event){$event.stopPropagation();return _vm.$handleRoute(
              { id: scope.row.id, log_id: scope.row.log_id },
              '/topic/topicRecord'
            )}}},[_vm._v(" 查看详情 ")]):_vm._e()]}},{key:"action2",fn:function(scope){return [(!_vm.$attrs.isCollect)?[(_vm.userInfo.id == scope.row.user_id)?_c('i',{staticClass:"el-icon-delete-solid",staticStyle:{"color":"#414141","font-size":"20px"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteRow(scope.row.id)}}}):_vm._e()]:[_c('i',{staticClass:"el-icon-star-on",staticStyle:{"color":"#f56c6c","font-size":"22px"},on:{"click":function($event){$event.stopPropagation();return _vm.meCollect(scope.row.id, scope.$index)}}})]]}}])})],1),(_vm.total > 0)?_c('div',{staticClass:"page",style:(_vm.$attrs.pageStyle || {})},[_c('el-pagination',{attrs:{"background":"","layout":"total,sizes,prev, pager, next","total":_vm.total,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"current-change":_vm.subjectList,"size-change":_vm.onSizeChange}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }